<template>
  <default>
    <div class="tw-mb-4 c-blue-text">
      <v-chip-group mandatory active-class="primary">
        <!-- Dashboard chip hidden on small screens -->
        <v-chip active-class="false" :to="{ name: 'Dashboard' }">
          Dashboard
        </v-chip>
        <span class="tw-mx-2 mt-2"> > </span>

        <v-chip active-class="false" :to="{ name: 'Products' }">
          Products
        </v-chip>
        <span class="tw-mx-2 mt-2"> > </span>

        <v-chip :to="{ name: 'KeymanPlan' }">
          {{ $options.name }}
        </v-chip>
      </v-chip-group>
    </div>

    <!-- Main Card for Keyman Plan Calculator -->
    <v-card elevation="2"
      class="main-card tw-px-4 md:tw-px-8 tw-py-6 md:tw-py-8 tw-rounded-lg tw-shadow-lg tw-bg-white">
      <!-- Title Section with Icon -->
      <div class="tw-flex tw-items-center tw-mb-6">
        <img src="/images/icons/top-icon.png" alt="Keyman Icon" class="tw-w-10 tw-h-10 tw-mr-3" />
        <h2 class="tw-text-2xl tw-font-semibold c-blue-text">Keyman Plan Calculator</h2>
      </div>

      <!-- Form Container with responsive styling and shadow for larger screens -->
      <div
        class="form-container tw-w-full tw-flex tw-flex-wrap tw-py-4 md:tw-py-6 tw-bg-gray-50 tw-rounded-md tw-shadow-inner lg:tw-shadow-lg">
        <keep-alive>
          <component :is="current" :productData="productData" :benefits="benefits"
            :productOptions="computedProductOptions" :terms="terms" :quoteSummaryData="quoteSummaryData"
            @to-quote-summary="toQuoteSummary" @fetchTerms="fetchTerms"></component>
        </keep-alive>
      </div>
    </v-card>
  </default>
</template>

<script>
import Default from '../../layouts/Default';
import CardTitle from '../../components/CardTitle';
import BackButton from '../../components/BackButton';
import QuoteSummary from '../../components/rfq/QuoteSummary';
import ClientQuotation from '../../components/rfq/akiba-halisi/Quote';
import ProductMixin from '@/mixins/ProductMixin';
import savedQuoteMixin from '../../mixins/savedQuoteMixin';

export default {
  name: 'KeymanPlan',
  metaInfo: {
    title: 'RFQ - Keyman Plan',
  },
  components: {
    QuoteSummary, BackButton, CardTitle, Default, ClientQuotation,
  },
  mixins: [ProductMixin, savedQuoteMixin],
  data() {
    return {
      current: ClientQuotation,
      productData: { prodCode: 2021197 },
      benefits: [],
      productOptions: [],
      terms: [],
      quoteSummaryData: {},
      termLimit: {
        min: 5,
        max: 20,
      },
    };
  },
  created() {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', 'keymanplan_page_visited_by_agent', {
        event_category: 'keyman plan component created',
        event_label: 'keyman plan component created',
      });
    }
  },
  mounted() {
    this.fetchProductOptions();
    this.fetchBenefits();
    this.fetchTerms(this.scanObject(this.productOptions, '0.optionCode', 2021216));
    this.checkSavedQuote('akiba');
  },
};
</script>

<style scoped>
.main-card {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 2rem;
}

.form-container {
  background-color: #f9fafb;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.05);
}

.benefits-section {
  padding: 1.5rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.benefits-section .tw-shadow-sm {
  transition: box-shadow 0.2s ease;
}

.benefits-section .tw-shadow-sm:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.v-checkbox__label {
  font-size: 0.9rem;
  color: #374151;
}
</style>
