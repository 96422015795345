<template>
  <div class="tw-py-2 md:tw-py-5 c-blue-text">
    <router-link :to="to" class="tw-flex tw-items-center"><v-icon color="primary">mdi-arrow-left</v-icon>
      <slot>Back</slot>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'BackButton',
  props: {
    to: {
      type: Object,
      default: () => ({ name: 'Dashboard' }),
    },
  },
};
</script>
